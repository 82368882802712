import Q from 'q';
import web_esIgn_esIgnRealNameController_queryAuthent from '@/lib/data-service/default/web_esIgn_esIgnRealNameController_queryAuthent.js';

export default {
  data () {
    return {
      loading: false,
      status: 0,
      realStatus: {
        realStatus: '',
      },
    }
  },
  methods: {
    req_detail () {
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function () {
          return web_esIgn_esIgnRealNameController_queryAuthent();
        })
        .then(function (res) {
          console.log(res)
          _this.realStatus = res;
          _this.loading = false;
        })
    }
    // goBack () {
    //   this.$router.push({
    //     name: '',
    //   })
    // }
  },
  activated () {
    this.req_detail();
  }
}