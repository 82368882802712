

const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request/__request_contentType_json`);

// 查询认证信息1接口地址： http://192.168.0.9:8080/doc.html#/yinzhilv_travel/es-ign-real-name-controller/QueryAuthentUsingPOST

const web_esIgn_esIgnRealNameController_queryAuthent = () => {

  const p = {
    method: 'post',
    urlSuffix: '/web/esIgn/esIgnRealNameController/queryAuthent',
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;
};
module.exports = web_esIgn_esIgnRealNameController_queryAuthent;


